import { useUserStore } from "@/stores/user"
import { useCategoriesStore } from '@/stores/categories'
import { useMenuStore } from "@/stores/menu"

export const useInitState = async (): Promise<void> => {
  console.log('Initializing store...')
  const { status, getSession, data } = useAuth()
  try {
    console.log('Initializing user session...')
    await getSession()

    const userStore = useUserStore()
    console.log("User session initialized", userStore.id)
  } catch (e) {
    console.error('Error initializing user session:', e)
  }

  const categoriesStore = useCategoriesStore()

  try {
    await categoriesStore.fetchCategories()
    await categoriesStore.fetchSubCategories()
    await categoriesStore.fetchCategoryGroups()
  } catch(e) {
    console.log('Error when categories were initializing:', e)
  }

  console.log('Categories initialized.')

  const menuStore = useMenuStore()
  menuStore.initializeMenu()

  console.log('Menu initialized.')

  const faqStore = useFAQStore()
  try {
    await faqStore.fetchFAQRoutes()
    console.log('FAQ initialized.')
  } catch(e) {
    console.log('Error when FAQ was initializing:', e)
  }

  if (status.value !== 'authenticated') {
    console.log('Store initialized. User not authenticated.')
    return
  }

  console.log('Store initialized.')

  const notificationStore = useNotificationsStore()
  try {
    await notificationStore.fetchNotifications()
  } catch(e) {
    console.log('Error when notifications were initializing:', e)
  }
  
  console.log('Notifications initialized.')

  const bookmarksStore = useBookmarksStore()
  try {
    await bookmarksStore.fetchBookmarks()
  } catch(e) {
    console.log('Error when bookmarks were initializing:', e)
  }
  
  console.log('Bookmarks initialized.')
}