import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import type { CodeConfirmationState } from '@/components/Authorization.vue'
import { useAuth } from '#imports'

export const useAuthorization = (setExpiresIn?: (targetState?: CodeConfirmationState, expiresIn?: number) => void) => {
  const { signIn } = useAuth()
  const userStore = useUserStore()
  const authStore = useAuthStore()

  const isCodeSending = ref(false)
  const sendingPhone = ref('')

  const sendCode = async (phone: string) => {
    authStore.toggleLoading()

    if (isCodeSending.value && sendingPhone.value === phone) {
      return
    }

    isCodeSending.value = true
    sendingPhone.value = phone

    userStore.temporarlyPhone = phone

    try {
      const result = await $fetch('/api/auth/send-code', {
        method: 'POST',
        body: JSON.stringify({ phone }),
      })

      isCodeSending.value = false
      return result
    } catch (error) {
      const message = error.response._data.message
      if (message.includes("You requested the maximum number of messages the last hour")) {
        authStore.setMaximumSMSError()
      } else if (message.includes("Phone number is invalid")) {
        authStore.setInvalidNumberError()
      }
    }
    
    authStore.toggleLoading()
    isCodeSending.value = false
  }

  const sendNewCode = async (targetState?: CodeConfirmationState) => {
    if (userStore.temporarlyPhone) {
      const { expiresIn } = await sendCode(userStore.temporarlyPhone)
      if (setExpiresIn) {
        setExpiresIn(targetState, expiresIn)
      }
    } else {
      throw new Error('Phone is not set')
    }
  }

  const authorize = async (code: string) => {
    if (userStore.temporarlyPhone) {
      console.log('is phone number')
      const { error } = await signIn('base', {
        username: userStore.temporarlyPhone,
        password: code,
        redirect: false,
      })
  
      if (error) {
        if (error === 'Invalid credentials') {
          throw new Error('Неверный код')
        } else {
          throw new Error(error)
        }
      }

      await useInitState()
    } else {
      throw new Error('Phone is not set')
    }
  }

  return { authorize, sendCode, sendNewCode }
}